.header {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  /* color: red; */
  /* padding: 18px 0; */
  position: fixed;
  background-color: white;
  width: 100%;
  top: 0;
  z-index: 99999;
  /* background-color:#da9b634d; */
  transition: 0.5s ease-in-out;
}
.link {
  color: #e06d06 !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  font-family: Inter;
}
.link1 {
  color: #e06d06 !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  font-family: Inter;
  transition: all 300ms ease;
}
/* .link1:hover{
    background-color: #E06D06;
    color: white !important;
    padding: .5rem;
    border-radius: 1rem;
} */
.logo {
  /* border: solid; */
  width: 20%;
}
.logo img {
  width: 60%;
}
/* .nav_menu{
    width: 50%;
} */
.link2 {
  background: #e06d06;
  color: white !important;
  font-family: Inter;
  width: 180px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav {
  width: 90% !important;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.smallscreen {
  display: none;
}
.dropdown-item:hover {
  background: none !important;
  color: none !important;
}
@media (max-width: 991px) {
  .Link {
    color: #f5f6ff !important;
    font-weight: 500 !important;
    font-family: Montserrat;
  }
  .link1 {
    color: #f5f6ff !important;
  }
  .signup {
    background: #f5f6ff;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .signup a {
    color: #1560bd !important;
    font-weight: 500 !important;
    font-family: Montserrat;
  }
  .smallscreen {
    display: block;
  }
  .smallnav {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1000;
    background: #e06d06;
    width: 100%;
    height: 100vh;
    display: flex;
    list-style: none;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    animation: showmenu 300ms ease forwards;
  }
  @keyframes showmenu {
    to {
      left: 0;
    }
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    font-size: 27px;
    color: #f5f6ff;
    cursor: pointer;
  }
  .nav_menu {
    margin-top: 5rem;
  }

  .link2 {
    background-color: #f5f6ff;
    color: #e06d06 !important;
  }
  nav {
    width: 100% !important;
    padding-bottom: 0.5rem;
  }
  .logo {
    /* border: solid; */
    width: 25%;
  }
  .logo img {
    width: 60%;
  }
}

@media (max-width: 567px) {
  .logo img {
    width: 90%;
  }
}
