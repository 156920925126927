.heroabout {
  /* border: solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heroaboutimg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../Images/jake-blucker-OJX7gIU3E6U-unsplash.jpg);
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  /* position: relative; */
  z-index: 1;
}
.heroaboutimg::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  /* height: 100%;      */
  width: 100%;
  opacity: 9;
  z-index: -1;
  background-color: #1e1e1e54;
}

.heroaboutimg h1 {
  text-align: center;
  color: #e06d06;
  font-size: 2rem;
  font-weight: 900;
  font-family: Inter;
}
.aboutcontent {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: center; */
}
.aboutcontent h1 {
  font-family: Inter;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  color: #e06d06;
}
.aboutcontent p {
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 400;
  /* text-align: center; */
  color: #1e1e1e;
}
.distributor {
  width: 90%;
}
/*   */
.distributorimg img {
  width: 50%;
  /* border: red solid; */
}
.distributor h1 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2.5rem;
  font-weight: 600;
}
.lagos h4,
.lagoss h4 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.locationL,
.lagoss {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  /* border: solid; */
}
.locationL h5 {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.l1 p {
  font-size: 1.1rem;
  font-family: Inter;

  word-break: break-all;
}
@media (max-width: 991px) {
  .heroaboutimg img {
    width: 60%;
    border: #e06d06 solid;
    border-radius: 2rem;
    margin: 2rem 0;
  }
  .aboutcontent p {
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 400;
    color: #1e1e1e;
  }
  .locationL,
  .lagoss {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    /* border: solid; */
  }
}
@media (max-width: 767px) {
  .aboutcontent p {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    color: #1e1e1e;
  }
  .locationL,
  .lagoss {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    /* border: solid; */
  }
  .heroaboutimg img {
    width: 100%;
    border: #e06d06 solid;
    border-radius: 2rem;
    margin: 2rem 0;
  }
  .distributor h1 {
    color: #e06d06;
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
