.faqhero {
  /* width: 90%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #da9b634d;
}
.faqq {
  width: 90%;
  /* border: red solid; */
}
.faq {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .faq img {
  width: 30%;
} */
.faqq h1 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.faqq p {
  text-align: center;
  width: 85%;
  font-family: Inter;
  font-size: 1.5rem;
  color: #1e1e1e;
  font-weight: 400;
}
.frequently {
  background-color: #ffb97999;
}
.qanda {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
  margin: auto;
}
.nonap > h1,
.speedlimiters > h1 {
  font-family: Inter;
  font-size: 2rem;
  font-weight: 600;
  color: #e06d06;
  text-align: center;
}
.contactlink{
  color: #e06d06;
  font-weight: 600;
}
@media (max-width: 991px) {
  .faqq p {
    text-align: center;
    width: 100%;
    font-family: Inter;
    font-size: 1.5rem;
    color: #1e1e1e;
    font-weight: 400;
  }
  .qanda {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin: auto;
  }
   .nonap > h1,
  .speedlimiters > h1 {
    margin-top: 1rem;
  }
}
@media (max-width: 767px) {
  .nonap > h1,
  .speedlimiters > h1 {
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 600;
    color: #e06d06;
    text-align: center;
  }
    .faqq p {
    text-align: center;
    width: 100%;
    font-family: Inter;
    font-size: 1rem;
    color: #1e1e1e;
    font-weight: 400;
  }
}
