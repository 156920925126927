.freetippp {
  background: rgba(218, 155, 99, 0.3);
  padding-top: 2rem;
}
.freetipshero,
.tipss,
.safetyhabits,
.offences {
  width: 90%;
  /* border: red solid; */
  margin: 0 auto;
}
.herotext,
.freeheroimg {
  width: 45%;
}
.freeheroimg img {
  width: 100%;
}
.writer p {
  padding: 0 !important;
  margin: 0 !important;
}
.writer .name {
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 600;
}

.herotext h1 {
  color: #e06d06;
  font-family: Inter;
  /* font-size: 1.3rem; */
  font-weight: 600;
}
.herotext p {
  font-family: Inter;
}
.safetyhabits {
  font-family: Inter;
}
.safetyhabits img {
  border: red solid;
  width: 100%;
}
.safetyhabits h5 {
  color: #e06d06;
  font-weight: 600;
  font-family: inter;
}
.tipss {
  font-family: Inter;
}
.effective li {
  list-style: initial;
  font-family: Inter;
}
.offences h3 {
  color: #e06d06;
  font-weight: 600;
  font-family: inter;
}
.offences p{
   font-family: inter;
}
.tableoffence, .tableacronmy{
  width: 100%;
}
.tableoffence tr:nth-child(even),.tableacronmy tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tableacronmy td,
.tableacronmy th ,
.tableoffence td,
.tableoffence th {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: 600;
  font-family: inter;
}
.offences .note{
   font-family: inter;
   font-weight: 600;
}
.freelinks{
  font-weight: 500;
  font-style: italic;
}
.tableacronmy td,
.tableacronmy th{
  text-align: center;
}
@media (max-width: 768px) {
  .herotext,
  .freeheroimg {
    width: 100%;
  }
  .freeheroimg img {
    width: 80%;
  }
  .offences {
    overflow: scroll;
  }
  .tableoffence {
    overflow-x: scroll;
    /* width: 95%; */
  }
}
