.faq h1{
    font-family: Inter;
    font-size: 2rem;
    color: #E06D06;
    font-weight: 400;
}

.faq h2 {
    font-family: Inter;
    font-size: 1.3rem;
    color:black;
    width: 45%;
    font-weight: 600;
}

.questions{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    transition: all 1s cubic-bezier(0,1,0,1);
}
.question{
    cursor: pointer;
    transition: all 1s cubic-bezier(0,1,0,1);
}

.item {
    background: #954904;
    margin: .3rem;
    border-radius: .5rem;
    transition: all 1s cubic-bezier(0,1,0,1);

}
.answer{
    color: black !important;
    overflow: hidden;
    max-height: 0;
    font-weight: 500;
    width: 100%;
    font-family: Inter;
    background-color: #FFB97999;
    font-size: 1rem;
    padding: 0rem 1rem;
    transition: all 1s cubic-bezier(0,1,0,1);
}
.question h6 {
    font-weight: 400;
    font-family: Inter;
    font-size: 1rem;
    color: #FFFFFF;
    padding: 0rem 1rem;

}
.question span{
    font-weight: 500;
    font-family: Inter;
    font-size: 2rem;
    color: #FFFFFF;
    transition: all 5s cubic-bezier(0,1,0,1);


        
}
.show {
    height: auto;
   color: black;
    max-height: 9999px;
    font-weight: 500;
    font-family: Inter;
    background-color: #FFB97999;
    font-size: 1rem;
    padding: 0rem 1rem;
    transition: all 1s cubic-bezier(0,1,0,1);

}
.seemore {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #954904;
    margin-top: 2rem;
    width: 150px;
    height: 50px;
    border-radius: 10px;
}

.seemore a {
    color: white !important;
    font-weight: 500;
    font-size: 1.5rem;
    text-transform: capitalize;
    font-family: Inter;
}
@media (max-width:991px) {
   .questions{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
} 
}
@media (max-width:768px) {
   .questions{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
} 
.faq h1{
    font-family: Inter;
    font-size: 1.5rem;
    color: #E06D06;
    width: 90%;
    font-weight: 400;
}

.faq h2 {
    font-family: Inter;
    font-size: 1rem;
    color: black;
    width: 90%;
    font-weight: 600;
}
}
