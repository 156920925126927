.heronap {
  background-image: url(../../../Images/jake-blucker-OJX7gIU3E6U-unsplash.jpg);
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  /* position: relative; */
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.heronap::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  /* height: 100%;      */
  width: 100%;
  opacity: 9;
  z-index: -1;
  background-color: #1e1e1e54;
}

.nonapdescript h1 {
  color: #e06d06;
  font-size: 3.5rem;
  font-weight: 900;
  font-family: Inter;
}
.nonapdescript p {
  color: white;
  font-size: 1.5rem;
  font-family: Inter;

  text-align: center;
  width: 90%;
}
/* .helps {
  background-color: #ffb97999;
  padding: 1rem;
} */
.nonapcontent {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nonapcontentimg {
  width: 45%;
}

.nonapcontentimg img {
  width: 80%;
}
.help {
  width: 90%;
}
.nonapcontent h1 {
  color: #e06d06;
  text-align: center;
  font-weight: 600;
  font-family: Inter;

  font-size: 3rem;
}
.nonapcontent p,
.helps p {
  font-size: 1.5rem;
  font-family: Inter;

  font-weight: 500;
}
.nonapworks {
  background-color: #ffb97999;
  /* margin: 1.5rem 0; */
}
.nopworkcontent1,
workshopimg,
.nonaphelps {
  width: 90%;
}
.nonapworks p {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Inter;
}
.nopworkcontent1 h1 {
  color: #e06d06;
  text-align: center;
  font-weight: 600;
  font-family: Inter;

  font-size: 3rem;
}
.nopworkcontent2 {
  font-size: 1.5rem;
  width: 90%;
  font-family: Inter;

  font-weight: 500;
}
.napworks {
  width: 90%;
}
.imgwork {
  width: 70%;
}
.napworksimg {
  width: 48%;
}
.napworksimg2 {
  width: 48%;
}
.napworksimg img {
  width: 100%;
}
.napworksimg2 img {
  width: 100%;
}
.nonaphelpsimg {
  width: 75%;
  /* border: red solid ; */
}
.nonaphelpsimg > div {
  width: 100%;
}
.nonaphelpsimg > div img {
  width: 100%;
}
.note {
  width: 90%;
  /* border: red solid; */
}
.note p {
  font-weight: 400;
  font-size: 1.2rem;
  font-family: Inter;
}
.feature {
  /* border: solid; */
  width: 90%;
  display: grid;
  /* border: red solid; */
  grid-template-columns: repeat(2, 1fr);
}

.feature h4 {
  color: #e06d06;
}
.featuresimg {
  display: flex;

  justify-content: center;
  align-items: center;
  /* border: red solid; */
}
.featuresimg img {
  width: 50%;
}
.feature li {
  list-style: initial;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 500;
}
/* media queries  */
@media (max-width: 991px) {
  .nonapdescript p {
    color: white;
    font-size: 1.3rem;
    font-family: Inter;
    text-align: center;
    width: 90%;
  }
}
@media (max-width: 767px) {
  .nopworkcontent1 h1 {
    color: #e06d06;
    text-align: center;
    font-weight: 600;
    font-family: Inter;
    font-size: 2.7rem;
  }
  .nonapworks p {
    font-size: 1.3rem;
    font-weight: 500;
    font-family: Inter;
  }
  .napworks {
    width: 70%;
  }
  .nonapcontent h1 {
    color: #e06d06;
    text-align: center;
    font-weight: 600;
    font-family: Inter;
    font-size: 2.7rem;
  }
  .nonapcontent p,
  .helps p {
    font-size: 1.3rem;
    font-family: Inter;
    font-weight: 500;
  }
  .note p {
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Inter;
  }
  .feature {
    /* border: solid; */
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 567px) {
  .nonapdescript h1 {
    color: #e06d06;
    font-size: 3rem;
    font-weight: 900;
    font-family: Inter;
  }
  .nonapdescript p {
    color: white;
    font-size: initial;
    font-family: Inter;
    text-align: center;
    width: 90%;
  }
  .nopworkcontent1 h1 {
    color: #e06d06;
    text-align: center;
    font-weight: 600;
    font-family: Inter;
    font-size: 2rem;
  }
  .nonapworks p {
    font-size: initial;
    font-weight: 500;
    font-family: Inter;
  }
  .napworks {
    width: 90%;
  }
  .imgwork {
    width: 100%;
  }
  .nonapcontent h1 {
    color: #e06d06;
    text-align: center;
    font-weight: 600;
    font-family: Inter;
    font-size: 2rem;
  }
  .nonapcontent p,
  .helps p {
    font-size:initial;
    font-family: Inter;
    font-weight: 500;
  }
  .note p {
    font-weight: 400;
    font-size: initial;
    font-family: Inter;
  }

  .nonaphelpsimg {
    width: 100%;
    /* border: red solid; */
  }
}
