.train {
  width: 90% !important;
  /* border: red solid; */
  margin-top: 2.5rem;
}
.imgsemi img {
  width: 100%;
}
.seminarcontent h1 {
  color: #e06d06;
  font-family: Inter;
  font-weight: 600;
  text-align: center;
}
.seminarcontent h4 {
  color: #e06d06;
  font-family: Inter;
  text-align: center;

  font-weight: 600;
  margin-top: 1.5rem;
}
.seminarcontent p {
  font-family: Inter;
  font-size: 1.3rem;
  /* text-align: center; */
  font-weight: 400;
}
.workshopimg {
  width: 70%;
}
.worshopimgfirst {
  width: 47%;
}
.worshopimgfirst img {
  width: 100%;
}
.workshopgroup2 {
  width: 47%;
}
.workshopgroup2 img {
  width: 100%;
}
.workshopcontent {
  width: 100%;
}
.workshopcontent h1 {
  font-family: Inter;
  font-size: 2.2rem;
  font-weight: 600;
  color: #e06d06;
  text-align: center;
}
.workshopcontent p {
  font-family: Inter;
  font-size: 1.3rem;
  /* text-align: center; */
  font-weight: 400;
}
.workshopcontent h5 {
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
  /* color: #e06d06; */
}
.workshopcontent h4 {
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
  color: #e06d06;
}
.workshopcontent ul li {
  list-style: initial;
  font-family: Inter;
  font-size: 1.3rem;
}
.module {
  border: solid 5px #e06d06;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.module h4 {
  text-align: center;
  font-family: Inter;

  color: #e06d06;
  font-size: 600;
}
.module ul li {
  list-style: initial;
  font-family: Inter;
  font-size: 1.1rem;
}
.module p {
  font-family: Inter;
  /* font-size: 1.3rem; */
}
.coursesss {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}
@media (max-width: 991px) {
  .seminarcontent p {
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .workshopimg {
    width: 100%;
  }
  .workshopcontent p {
    font-family: Inter;
    font-size: 1.22rem;
    font-weight: 400;
  }
  .workshopcontent ul li {
    list-style: initial;
    font-family: Inter;
    font-size: 1.2rem;
  }
  .coursesss {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .module {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .coursesss {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 1rem;
  }
  .seminarcontent h1 {
    color: #e06d06;
    font-family: Inter;
    font-weight: 600;
    text-align: center;
    font-size: 1.4rem;
    word-break: break-all;
  }
  .workshopcontent h1 {
    font-family: Inter;
    font-size: 1.7rem;
    font-weight: 600;
    color: #e06d06;
    text-align: center;
  }
}
