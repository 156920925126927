.speedlimit {
  background-image: url(../../../Images/wes-hicks-ymClijdfDH0-unsplash.jpg);
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  /* position: relative; */
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.speedlimit::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  /* height: 100%;      */
  width: 100%;
  opacity: 9;
  z-index: -1;
  background-color: #00000054;
}
.speedlimitscript,
.package  {
  width: 90%;
  /* border: solid red; */
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; */
}
.packagecontent {
  /* width: 45%; */
  margin-top: 2rem;
}
.speedlimitscript h1 {
  font-size: 3.5rem;
  font-weight: 800;
  text-transform: capitalize;
  color: #e06d06;
  margin-bottom: 3rem;
}
.speedlimitscript p {
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  text-align: center;
}
.packagecontent ul li {
  list-style: initial;
  font-size: 1.3rem;
  margin-top: 1rem;
  font-weight: 600;
  font-family: Inter;
}
.packagecontent h5 {
  font-size: 1.8rem;
  font-family: Inter;
  font-weight: 600;
  color: #e06d06;
}
.packagecontent p {
  font-size: 1.3rem;
  font-family: Inter;
  font-weight: 400;
  /* color: #e06d06; */
}
.packagecontents p {
  font-size: 1.3rem;
  font-family: Inter;
  font-weight: 600;
  /* color: #e06d06; */
}
.packagecontents h5 {
  font-size: 1.8rem;
  font-family: Inter;
  font-weight: 600;
  color: #e06d06;
}
.speedlimitersect{
  background: rgba(218, 155, 99, 0.3);
  /* border: solid; */
  width: 100%
}
@media (max-width: 567px) {
  .speedlimitscript h1 {
    font-size: 2.5rem;
    font-weight: 800;
    text-transform: capitalize;
    color: #e06d06;
    margin-bottom: 1rem;
  }
  .speedlimitscript p {
    font-size: initial;
    font-weight: 500;
    color: white;
    text-align: center;
  }
  .packagecontent ul li {
    list-style: initial;
    font-size: initial;
    margin-top: 1rem;
    font-weight: 600;
    font-family: Inter;
  }
  .packagecontent h5 {
    font-size: 1.5rem;
    font-family: Inter;
    font-weight: 600;
    color: #e06d06;
  }
  .packagecontent p {
    font-size: initial;
    font-family: Inter;
    font-weight: 400;
    /* color: #e06d06; */
  }
  .packagecontents p {
    font-size: initial;
    font-family: Inter;
    font-weight: 600;
    /* color: #e06d06; */
  }
  .packagecontents h5 {
    font-size: 1.5rem;
    font-family: Inter;
    font-weight: 600;
    color: #e06d06;
  }
}
