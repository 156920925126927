.contact {
  background: #954904;
  padding: 1rem 0rem 3rem 0rem !important;
}
.contact h1 {
  font-family: Inter;
  font-size: 2rem;
  color: #e06d06;
  text-align: center;
  font-weight: 600;
}
.contact img {
  width: 65%;
}
.contact .form {
  background: #fff;
  border-radius: 1rem;
}
.loctionscontacts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(251, 249, 249, 0.926);
  /* background: red; */
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  border: 2px solid #d9d9d9;
  border-radius: 20px;
  color: #121212;
  margin: 0 0 8px 0;
  font-family: Inter;
  padding: 18px 26px;
  outline: none;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
  font-family: Inter;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  color: #1e1e1e66;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
  font-family: Inter;
}

.contact form button {
  font-weight: 700;
  color: white;
  background-color: #e06d06;
  padding: 0.5rem 1rem;
  font-family: Inter;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 25px;
  border-radius: 0.5rem;
  border: none;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}
.loctaionicon {
  color: #954904;
  font-size: 2rem;
  font-weight: 600;
}
.locationcontent {
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.loader {
  border: 6px solid rgb(154, 154, 154); /* Light grey */
  border-top: 6px solid #ffffff; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 767px) {
  .loctionscontacts {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }
}
@media (max-width: 540px) {
  .loctaionicon {
    color: #954904;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .locationcontent {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    /* width: 50%; */
    text-align: center;
  }
  .loctionscontacts {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }
}
