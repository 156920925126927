.partner {
    /* padding: 1rem 3rem 0rem 3rem !important;
    height: 200px; */
    margin-bottom: 1rem;
}
.idk{
    width: 30%;
}
.lastma{
    width: 30%;
}
.frsc{
    width: 30%;
    /* border: #E06D06 solid; */
}

.partner h1{
    font-family: Inter;
    font-size: 2rem;
    color: #E06D06;
    text-align: center;
    font-weight: 400;
}
.ourpartnersimg{
    width: 60%;
}
@media (max-width:767px){
    .ourpartnersimg {
            width: 100%;
            height: 200px;
        }
}
@media (max-width:567px){
    .partner h1 {
        font-family: Inter;
        font-size: 2rem;
        color: #E06D06;
        text-align: center;
        font-weight: 400;
    }
    .ourpartnersimg {
        width: 100%;
        height: 200px;
    }
    .idk{
    width: 50%;
    }
    .idk img {
    width: 100%;
    }
    .lastma{
    width: 50%;
    }
    .frsc{
    width: 50%;
    /* border: #E06D06 solid; */
    }
}