.heroservices {
  /* padding: 1rem 3rem 0rem 3rem !important; */
  background: #da9b634d;
}
.services {
  width: 90% !important;
}
.services h1 {
  font-family: Inter;
  font-size: 2rem;
  color: #e06d06;
  font-weight: 400;
}
.nonapkit-img {
  width: 45%;
  display: flex;
  justify-content: center;
  /* border: red solid; */
}
.nonapkit-img img {
  width: 70%;
}
.nonaptext {
  width: 45%;
}
.nonaptext h3 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2.3rem;
  font-weight: 600;
}
.nonaptext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 400;
}
.readmore {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e06d06;
  width: 150px;
  height: 50px;
  border-radius: 10px;
}
.readmore a {
  color: white !important;
  font-weight: 500;
  font-size: 1rem;
  font-family: Inter;
}
.training-img {
  width: 45%;
  display: flex;
  justify-content: center;
  /* border: solid; */
}
.training-img img {
  width: 70%;
}

.trainingtext {
  width: 45%;
}

.trainingtext h3 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2.3rem;
  font-weight: 600;
}

.trainingtext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 400;
}
.speedlimiter-img {
  width: 45%;
  display: flex;
  justify-content: center;
}
.speedlimiter-img img {
  width: 70%;
}
.speedlimitertext {
  width: 45%;
}

.speedlimitertext h3 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2.3rem;
  font-weight: 600;
}

.speedlimitertext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 400;
}
.freetips-img {
  width: 45%;
  display: flex;
  justify-content: center;
}
.freetips-img img {
  width: 70%;
}

.freetipstext {
  width: 45%;
}

.freetipstext h3 {
  color: #e06d06;
  font-family: Inter;
  font-size: 2.5rem;
  font-weight: 600;
}

.freetipstext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 400;
}
@media (max-width: 991px) {
  .nonapkit-img {
    width: 45%;
    display: flex;
    justify-content: center;
    /* border: red solid; */
  }

  .nonapkit-img img {
    width: 100%;
  }

  .nonaptext {
    width: 45%;
  }

  .nonaptext h3 {
    color: #e06d06;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
  }

  .nonaptext p {
    color: #1e1e1e;
    font-family: Inter;
    font-size: 1.3rem;
    font-weight: 400;
  }
  .training-img {
    width: 45%;
    display: flex;
    justify-content: center;
    /* border: solid; */
  }

  .training-img img {
    width: 100%;
  }

  .trainingtext {
    width: 45%;
  }

  .trainingtext h3 {
    color: #e06d06;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
  }

  .trainingtext p {
    color: #1e1e1e;
    font-family: Inter;
    font-size: 1.3rem;
    font-weight: 400;
    /* border: solid; */
  }

  .speedlimiter-img {
    width: 45%;
    display: flex;
    justify-content: center;
  }

  .speedlimiter-img img {
    width: 100%;
  }

  .speedlimitertext {
    width: 45%;
  }

  .speedlimitertext h3 {
    color: #e06d06;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
  }

  .speedlimitertext p {
    color: #1e1e1e;
    font-family: Inter;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .freetips-img img {
    width: 100%;
  }

  .freetipstext {
    width: 45%;
  }

  .freetipstext h3 {
    color: #e06d06;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
  }

  .freetipstext p {
    color: #1e1e1e;
    font-family: Inter;
    font-size: 1.3rem;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .nonapkit-img {
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: red solid; */
  }
  .nonaptext {
    width: 100%;
    margin-top: 1.5rem;
  }
  .trainingtext {
    width: 100%;
    margin-top: 2rem;

  }
  .training-img {
    width: 100%;
  }

  .speedlimiter-img {
    width: 100%;
  }
  .speedlimitertext {
    width: 100%;
    margin-top: 2rem;
  }

  .speedlimiter-img {
    width: 100%;
  }
  .speedlimitertext {
    width: 100%;
    margin-top: 2rem;

  }
  .freetips-img {
    width: 100%;
  }
  .freetips-img img {
    width: 90%;
  }
  .freetipstext {
    width: 100%;
    margin-top: 2rem;

  }
}
@media (max-width: 567px) {
  .nonapkit-img {
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: red solid; */
  }
  .nonapkit-img img {
    width: 90%;
  }
  .nonaptext {
    width: 100%;
    margin-top: rem;
  }
  .trainingtext {
    width: 100%;
  }
  .training-img {
    width: 100%;
  }
  .training-img img {
    width: 90%;
  }

  .speedlimiter-img {
    width: 100%;
  }
  .speedlimiter-img img {
    width: 90%;
  }
  .speedlimitertext {
    width: 100%;
  }
  .freetips-img {
    width: 100%;
  }
  .freetips-img img {
    width: 90%;
  }
  .freetipstext {
    width: 100%;
  }
  .nonaptext h3 {
  color: #e06d06;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
}
.nonaptext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
}

.trainingtext h3{
    color: #e06d06;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
}
.trainingtext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
}
.speedlimitertext h3 {
     color: #e06d06;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;  
}
.speedlimitertext p {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
}
.freetipstext p{
    color: #1e1e1e;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
}
.freetipstext h3{
    color: #e06d06;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
}
.services h1 {
  font-family: Inter;
  font-size: 2rem;
  color: #e06d06;
  font-weight: 400;
}
}
